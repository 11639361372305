export default async function inicializarFirebaseMessagingSW() {
  try {
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js').catch((error) => {
      // eslint-disable-next-line no-console
      console.warn('Error al registrar el Service Worker:', error);
    });
    return registration;
  } catch (error) {
    throw ('Error al registrar el Service Worker:', error);
  }
}
