import empleadosApi from 'apis/EmpleadosApi';

// eslint-disable-next-line import/prefer-default-export
export const moodleToken = async () => {
  return empleadosApi.get('/moodle');
};

export default function obtenerEstadisticas(legajo) {
  return empleadosApi.get('/capacitacion/estadisticas', { params: { legajo } });
}
