import { useTheme } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ACTIVIDAD_PENDIENTE } from 'pages/estudios/capacitacion/constants';
import EtiquetaEstado from '../EtiquetaEstado';

export default function CursoCard({ 
  nombre, 
  categoria, 
  duracion, 
  fecha, 
  estado, 
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box p={2} bgcolor="white" borderRadius="10px" my={1}>
      <Grid container gap={1}>
        <Grid item xs={8}> 
          <Typography variant="h5" fontWeight={600} fontSize="1rem">
            {nombre}
          </Typography>
          <Typography variant="h6" fontSize="0.9rem">
            {categoria}
          </Typography>
        </Grid>
        <Grid item xs={3.5}>
          <EtiquetaEstado estado={estado} theme={theme} />
        </Grid>
      </Grid>
      <Grid container my={1} justifyContent="space-between">
        <Grid item> 
          <Typography fontWeight={600} display="inline">
            {t('pages.estudios.capacitacion.duracion')}
          </Typography>
          <Typography display="inline">
            {duracion}
          </Typography>
         
        </Grid>
        {estado !== ACTIVIDAD_PENDIENTE && ( 
          <Grid item mx={3}> 
            <Typography fontWeight={600} display="inline">
              {t('pages.estudios.capacitacion.realizacion')}
            </Typography>
            <Typography display="inline">
              {fecha}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
