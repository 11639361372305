import { Grid, MenuItem } from '@mui/material';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import FormikSelect from 'components/selects/FormikSelectComponent';
import useEducacion from 'hooks/useEducacion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OTRO_AREA_ID } from '../../constants';

export default function AreaEstudio({ form }) {
  const { t } = useTranslation();
  const { state: { filtros } } = useEducacion();
  const [esOtroArea, setEsOtroArea] = useState(false);

  useEffect(() => {
    const otroArea = form.values.area_estudio === OTRO_AREA_ID;
    setEsOtroArea(otroArea);
  }, [form.values.area_estudio]);

  return ( 
    <>
      <Grid item xs={12} md={esOtroArea ? 1.5 : 3} py={1} sx={{ pr: { md: 2 } }}>
        <FormikSelect
          id="area_estudio"
          label={t('pages.estudios.formulario.area_estudio')}
          formik={form}
          size="small"
          fullWidth
        >
          {filtros?.areas?.map((area) => (
            <MenuItem key={area.value} value={area.value}>
              {area.label}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      {esOtroArea && ( 
        <Grid item xs={12} md={1.5} py={1}>
          <FormikTextField
            id="area_estudio_descripcion"
            label={t('pages.estudios.formulario.area_estudio_descripcion')}
            placeholder={t('pages.estudios.formulario.area_estudio_placeholder')}
            formik={form}
            size="small"
          />
        </Grid>
      )}
    </>
  );
}
