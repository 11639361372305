import {
  Grid, 
} from '@mui/material';
import EmpleadoEstadistica from './components/EmpleadoEstadistica';

export default function CapacitacionView() {
  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <EmpleadoEstadistica />
      </Grid>
    </Grid>
  );
}
