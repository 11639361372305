import {
  Button,
  Grid, MenuItem, Typography, 
} from '@mui/material';
import Card from 'components/card/CardComponent';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import FormikSelect from 'components/selects/FormikSelectComponent';
import { useFormik } from 'formik';
import useEducacion from 'hooks/useEducacion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { ESTADO_EN_CURSO } from '../../constants';
import educacionSchema from '../../schemas/educacion.schema';
import TipoEstudioTitulo from './TipoEstudioTitulo';
import AreaEstudio from './AreaEstudio';
import formatEstudios from '../../utils/formatEstudios';
import FechaDuracion from './FechaDuracion';

export default function Formulario({
  titulo, 
  textoBotonGuardar, 
  educacion,
  handleSubmit,
}) {
  const { t } = useTranslation();
  const { state: { filtros }, obtenerFiltros } = useEducacion();

  useEffect(() => {
    obtenerFiltros();
  }, []);

  const initialValues = {
    titulo: educacion?.titulo || '',
    titulo_descripcion: educacion?.titulo_label || '',
    tipo_estudio: educacion?.tipo_estudio || '',
    tipo_estudio_descripcion: educacion?.tipo_estudio_label || '',
    establecimiento: educacion?.establecimiento || '',
    area_estudio: educacion?.area_estudio || '',
    area_estudio_descripcion: educacion?.area_estudio_label || '',
    estado: educacion?.estado || '',
    fecha_inicio: educacion?.fecha_inicio ? parseISO(educacion.fecha_inicio) : null,
    fecha_fin: educacion?.fecha_fin ? parseISO(educacion.fecha_fin) : null,
    duracion: educacion?.duracion || '',
    al_presente: educacion?.al_presente || false,
    comentario: educacion?.comentario || '', 
  };

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: educacionSchema(),
    onSubmit: (values) => {
      const valoresFormateados = formatEstudios(educacion, values);
      handleSubmit(valoresFormateados);
    },
  });

  useEffect(() => {
    const { estado } = form.values;

    if (estado !== ESTADO_EN_CURSO && form.values.al_presente) {
      form.setFieldValue('al_presente', false);
    }

    if (estado === ESTADO_EN_CURSO && !form.values.al_presente) {
      form.setFieldValue('al_presente', true);
      form.setFieldValue('fecha_fin', '');
    }
  }, [form.values.estado, form.values.al_presente]);

  return (
    <Card sx={{ width: '100%' }}>
      <form onSubmit={form.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Typography fontWeight={550}>{titulo}</Typography>
          </Grid>

          <TipoEstudioTitulo form={form} />

          <Grid item xs={12} md={2} py={1} sx={{ pr: { md: 2 } }}>
            <FormikTextField
              id="establecimiento"
              label={t('pages.estudios.formulario.establecimiento')}
              placeholder={t('pages.estudios.formulario.establecimiento_placeholder')}
              formik={form}
              size="small"
            />
          </Grid>

          <AreaEstudio form={form} />

          <Grid container>
            <Grid item xs={12} md={3} py={1} sx={{ pr: { md: 2 } }}>
              <FormikSelect
                id="estado"
                label={t('pages.estudios.formulario.estado')}
                formik={form}
                size="small"
                fullWidth
              >
                {filtros?.estados?.map((estado) => (
                  <MenuItem key={estado.value} value={estado.value}>
                    {estado.label}
                  </MenuItem>
                ))}
              </FormikSelect>
            </Grid>

            <FechaDuracion form={form} />

            <Grid item xs={12} md pb={1} sx={{ pt: { md: 1 } }}>
              <FormikTextField
                id="comentario"
                label={t('pages.estudios.formulario.comentario')}
                formik={form}
                size="small"
              />
            </Grid>

            <Grid container item xs={12} py={1} justifyContent="end">
              <Grid item xs md={3}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                >
                  {textoBotonGuardar}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}
