import {
  Grid, MenuItem, Select, Typography, 
} from '@mui/material';
import { MODALIDADES } from 'pages/estudios/capacitacion/constants';
import { useTranslation } from 'react-i18next';
import CursoCard from './CursoCard';

export default function EmpleadoMobile({ 
  modalidadSeleccionada, 
  handleChangeModalidad, 
  estadisticasSeleccionadas,
}) {
  const { t } = useTranslation();
  return ( 
    <Grid container item xs={12} lg={8.5} mt={2.5}>
      <Grid item xs={12}>
        <Select
          id="modalidad"
          value={modalidadSeleccionada}
          onChange={handleChangeModalidad}
          fullWidth
          size="smaller"
        >
          {MODALIDADES.map((modalidad) => (
            <MenuItem key={modalidad.id} value={modalidad.id}>
              {modalidad.nombre}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} my={1}>
        {estadisticasSeleccionadas.length > 0 ? (
          estadisticasSeleccionadas.map((curso) => (
            <CursoCard
              key={`${curso.descripcion}-${curso.fecha}`}
              nombre={curso.descripcion}
              categoria={curso.categoria}
              duracion={curso.duracion}
              fecha={curso.fecha}
              estado={curso.estado}
            />
          ))
        ) : (
          <Typography>{t('pages.estudios.capacitacion.sin_cursos')}</Typography>
        )}
      </Grid>
    </Grid>
  );
}
