import { Grid, Typography } from '@mui/material';
import BorderLinearProgress from 'components/progress/RoundedProgressBarComponent';
import { floatToPorcentaje } from '../../utils';

export default function PorcentajeModalidad({ titulo, value }) {
  const valorFormateadoString = floatToPorcentaje(value);
  return (
    <Grid item py={1} flex={1}>
      <Typography>{titulo}</Typography>
      <BorderLinearProgress
        variant="determinate"
        value={value}
        height={5}
      />
      <Typography color="accent.main" fontWeight={500}>
        {valorFormateadoString}
      </Typography>
    </Grid>
  );
}
