import { IconCirclePlus } from '@cooperativaobrera/react-icons/tabler-icons';
import { Button, Typography } from '@mui/material';
import useEducacion from 'hooks/useEducacion';
import { useTranslation } from 'react-i18next';

export default function BotonAgregar({ cantidadEstudios }) {
  const { t } = useTranslation();
  const { handleEstado } = useEducacion();

  const handleCreate = () => {
    handleEstado({ componente: 'CrearEstudio', data: {} });
  };

  return (
    <Button sx={{ px: 1 }} onClick={() => handleCreate()}> 
      <IconCirclePlus />
      <Typography mx={1}> 
        {cantidadEstudios > 0 
          ? t('pages.estudios.mis_estudios.agregar') 
          : t('pages.estudios.mis_estudios.agregar_primer') }
      </Typography>
    </Button>
  );
}
