import ProtectedRoute from 'components/routes/ProtectedRoute';
import IngresarLayout from 'components/layouts/IngresarLayoutComponent';
import InicioLayout from 'components/layouts/InicioLayoutComponent/InicioLayoutComponent';
import { Route, Routes } from 'react-router-dom';
import EvaluacionDesempeñoView from 'pages/evaluacionDesempeño/EvaluacionDesempeñoView';
import InicioView from 'pages/inicio/InicioView';
import LoginView from 'pages/login/LoginView';
import NotFoundView from 'pages/notFound/NotFoundView';
import RestablecerContraseñaView from 'pages/restablecerContraseña/RestablecerContraseñaView';
import SeguridadView from 'pages/seguridad/SeguridadView';
import SolicitudesView from 'pages/solicitudes/page';
import VacacionesPage from 'pages/vacaciones/page';
import ValidateDataView from 'pages/validacion/ValidateDataView';
import CertificadoLaboralPage from 'pages/solicitudes/certificado-laboral/page';
import TarjetaEmpleadoPage from 'pages/tarjeta-empleado/page';
import ResumenCuentaPage from 'pages/tarjeta-empleado/resumen-cuenta/page';
import CasaPersonalPage from 'pages/tarjeta-empleado/casa-personal/page';
import BmpjPage from 'pages/tarjeta-empleado/bmpj/page';
import ReciboSueldoPage from 'pages/recibo/page';
import GenerarPinPage from 'pages/recibo/generar-pin/page';
import PendienteHabilitacionPage from 'pages/recibo/pendiente/page';
import { useEffect, useRef } from 'react';
import useFirebase from 'hooks/useFirebase';
import BloqueadoTemporalmentePage from 'pages/recibo/bloqueado/page';
import PedidoPrestamoPage from 'pages/solicitudes/pedido-prestamo/page';
import EstudiosPage from 'pages/estudios/page';
import EducacionView from 'pages/estudios/educacion/EducacionView';
import CapacitacionView from 'pages/estudios/capacitacion/CapacitacionView';
import useAuth from 'hooks/useAuth';
import useUserHasPermission from 'hooks/useUserHasPermission';
import { PERMISO_NOTIFICACIONES } from 'constants/permisos';

export default function AppRoutes() {
  const { initializeFirebase } = useFirebase();
  const { state: { session } } = useAuth();
  const notificacionesHabilitadas = useUserHasPermission(PERMISO_NOTIFICACIONES);
  const prevInicializarServiceWorker = useRef(null);

  useEffect(() => {
    const inicializarServiceWorker = Boolean(session && notificacionesHabilitadas);
    
    if (prevInicializarServiceWorker.current !== inicializarServiceWorker) {
      prevInicializarServiceWorker.current = inicializarServiceWorker;
      initializeFirebase(inicializarServiceWorker);
    }
  }, [session, notificacionesHabilitadas, initializeFirebase]);

  return (
    <Routes>
      <Route path="/ingresar" element={<IngresarLayout />}>
        <Route index element={<LoginView />} />
      </Route>
      <Route path="/restablecer-contraseña" element={<IngresarLayout />}>
        <Route index element={<RestablecerContraseñaView />} />
      </Route>
      <Route path="/validar" element={<IngresarLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <ValidateDataView />
            </ProtectedRoute>
          )}
        />
      </Route>
      <Route path="/inicio" element={<InicioLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <InicioView />
            </ProtectedRoute>
          )}
        />
      </Route>
      <Route path="/seguridad" element={<InicioLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <SeguridadView />
            </ProtectedRoute>
          )}
        />
      </Route>
      <Route path="/evaluacion-desempeño" element={<InicioLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <EvaluacionDesempeñoView />
            </ProtectedRoute>
          )}
        />
      </Route>
      <Route path="/solicitudes" element={<InicioLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <SolicitudesView />
            </ProtectedRoute>
          )}
        />
        <Route
          path="/solicitudes/certificado-laboral"
          element={<CertificadoLaboralPage />}
        />
        <Route path="/solicitudes/pedido-prestamo" element={<PedidoPrestamoPage />} />
      </Route>
      <Route path="/vacaciones" element={<InicioLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <VacacionesPage />
            </ProtectedRoute>
          )}
        />
      </Route>
      <Route path="/tarjeta-empleado" element={<InicioLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <TarjetaEmpleadoPage />
            </ProtectedRoute>
          )}
        />
        <Route path="/tarjeta-empleado/resumen-cuenta" element={<ResumenCuentaPage />} />
        <Route path="/tarjeta-empleado/casa-personal" element={<CasaPersonalPage />} />
        <Route path="/tarjeta-empleado/bmpj" element={<BmpjPage />} />
      </Route>
      <Route path="/estudios" element={<InicioLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <EstudiosPage />
            </ProtectedRoute>
          )}
        />
        <Route path="/estudios/educacion" element={<EducacionView />} />
        <Route path="/estudios/capacitacion" element={<CapacitacionView />} />
      </Route>
      <Route path="/recibo" element={<InicioLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <ReciboSueldoPage />
            </ProtectedRoute>
          )}
        />
        <Route
          path="/recibo/generar-pin"
          element={<GenerarPinPage />}
        />
        <Route
          path="/recibo/pendiente"
          element={<PendienteHabilitacionPage />}
        />
        <Route
          path="/recibo/bloqueado"
          element={<BloqueadoTemporalmentePage />}
        />
      </Route>
      <Route path="/recibo" element={<InicioLayout />}>
        <Route
          index
          element={(
            <ProtectedRoute>
              <ReciboSueldoPage />
            </ProtectedRoute>
          )}
        />
        <Route
          path="/recibo/generar-pin"
          element={<GenerarPinPage />}
        />
        <Route
          path="/recibo/pendiente"
          element={<PendienteHabilitacionPage />}
        />
      </Route>
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
}
