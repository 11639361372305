import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { getColor } from '../../pages/estudios/capacitacion/utils/index';

export default function SemiCircularProgressBar({ valor }) {
  return (
    <Gauge
      height={100}
      value={valor}
      startAngle={-85}
      endAngle={85}
      text={({ value }) => `${value}%`}
      innerRadius={80}
      outerRadius={85}
      sx={(theme) => ({
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: '2.2rem',
          fontWeight: '600',
          transform: 'translate(0px, -25px)',
        },
        [`& .${gaugeClasses.valueArc}`]: {
          fill: getColor(valor),
        },
        [`& .${gaugeClasses.referenceArc}`]: {
          fill: theme.palette.grey[300],
        },
      })}
      cornerRadius="50%"
    />
  );
}
