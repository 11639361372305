import {
  ACTIVIDAD_PENDIENTE,
  ACTIVIDAD_REALIZADA,
  ACTIVIDAD_VENCIDA,
} from '../constants';

export const getColor = (value) => {
  if (value <= 12.5) return '#E6241A';
  if (value <= 25) return '#EA541F';
  if (value <= 37.5) return '#F38636';
  if (value <= 50) return '#EBC046';
  if (value <= 62.5) return '#BBC070';
  if (value <= 75) return '#7CC339';
  if (value <= 87.5) return '#67A62F';
  return '#0C933A';
};

export const getColorByEstado = (estado, theme) => {
  switch (estado) {
    case ACTIVIDAD_PENDIENTE:
      return theme.palette.accent.main;
    case ACTIVIDAD_REALIZADA:
      return theme.palette.success.main;
    case ACTIVIDAD_VENCIDA:
      return theme.palette.error.main;
    default:
      return theme.palette.error.main;
  }
};

export const getEstadoLabel = (estado) => {
  switch (estado) {
    case ACTIVIDAD_REALIZADA:
      return 'Realizado';
    case ACTIVIDAD_VENCIDA:
      return 'Vencido';
    case ACTIVIDAD_PENDIENTE:
      return 'Pendiente';
    default:
      return 'Desconocido';
  }
};

/**
 * Convierte un porcentaje en string a un float
 * @param porcentaje Ejemplo: "%40.25"
 * @returns valor en formato float. Ejemplo: 40.25
 */
export function porcentajeStringToFloat(porcentaje) {
  const numero = parseFloat(porcentaje.replace('%', '').trim());

  return !Number.isNaN(numero) ? parseFloat(numero.toFixed(4)) : 0;
}

/**
 * Toma un número y lo convierte en un porcentaje. Si el número es
 * un entero, no se agregarán decimales. Si el número tiene decimales, se mostrarán
 * hasta 2 cifras decimales.
 * @param numero a formatear
 * @returns Una cadena que representa el número formateado como un porcentaje.
 * @example
 *
 * // Retorna "55%"
 * formatearFloat(0.55);
 *
 * // Retorna "55.35%"
 * formatearFloat(0.5535);
 */
export function floatToPorcentaje(numero) {
  return (
    `${(numero).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}%`
  );
}
