export default function CapacitacionesIcon({
  width = 20,
  height = 20,
  stroke = '#3C3C3C',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...props}
    >
      <path d="M24.04 5.8V3.56C24.04 2.88105 23.7703 2.2299 23.2902 1.74981C22.8101 1.26971 22.159 1 21.48 1H3.56004C2.88108 1 2.22994 1.26971 1.74984 1.74981C1.26975 2.2299 1.00004 2.88105 1.00004 3.56V17.64C1.00004 17.9795 1.13489 18.3051 1.37494 18.5451C1.61499 18.7851 1.94056 18.92 2.28004 18.92H4.08306" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5195 16.6787C12.5195 16.4241 12.4184 16.18 12.2384 15.9999C12.0583 15.8199 11.8141 15.7188 11.5595 15.7188H7.71953C7.46492 15.7188 7.22074 15.8199 7.04071 15.9999C6.86067 16.18 6.75953 16.4241 6.75953 16.6787V17.6387C6.75953 17.8934 6.86067 18.1375 7.04071 18.3176C7.22074 18.4976 7.46492 18.5987 7.71953 18.5987H11.5595C11.8141 18.5987 12.0583 18.4976 12.2384 18.3176C12.4184 18.1375 12.5195 17.8934 12.5195 17.6387V16.6787Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.8008 11.5597C17.8008 12.3235 18.1042 13.0561 18.6443 13.5962C19.1844 14.1363 19.917 14.4397 20.6808 14.4397C21.4446 14.4397 22.1771 14.1363 22.7172 13.5962C23.2574 13.0561 23.5608 12.3235 23.5608 11.5597C23.5608 10.7959 23.2574 10.0633 22.7172 9.52322C22.1771 8.98312 21.4446 8.67969 20.6808 8.67969C19.917 8.67969 19.1844 8.98312 18.6443 9.52322C18.1042 10.0633 17.8008 10.7959 17.8008 11.5597Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.3594 21.6403V20.2003C16.3594 19.4365 16.6628 18.704 17.2029 18.1638C17.743 17.6237 18.4756 17.3203 19.2394 17.3203H22.1194C22.8832 17.3203 23.6157 17.6237 24.1558 18.1638C24.6959 18.704 24.9994 19.4365 24.9994 20.2003V21.6403" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.7998 5.80078H16.3598" stroke={stroke} strokeLinecap="round" />
      <path d="M5.7998 9.64062H11.5598" stroke={stroke} strokeLinecap="round" />
    </svg>
  );
}
