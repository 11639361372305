import { Button, Typography } from '@mui/material';
import ModalActions from 'components/modal/Modal2/ModalActionsComponent';
import Modal from 'components/modal/Modal2/ModalComponent';
import ModalHeader from 'components/modal/Modal2/ModalHeaderComponent';
import useAlerts from 'hooks/useAlerts';
import useEducacion from 'hooks/useEducacion';
import { useTranslation } from 'react-i18next';
import { eliminarEstudioAPI } from '../services/EducacionService';

export default function EliminarEstudioModal({ modal, handleClose }) {
  const { t } = useTranslation(); 
  const { obtenerEducacion } = useEducacion();
  const { openSnackbar } = useAlerts();

  const handleDelete = (id) => {
    eliminarEstudioAPI(id)
      .then((res) => {
        openSnackbar({
          message: res.data.message,
          severity: 'success',
        });
        obtenerEducacion();
        handleClose();
      })
      .catch((err) => {
        openSnackbar({
          message: err,
          severity: 'error',
        });
      });
  };

  return ( 
    <Modal open={modal.open} handleClose={handleClose}>
      <ModalHeader>
        <Typography variant="h6" fontWeight={600}>
          {t('pages.estudios.mis_estudios.modales.eliminar.titulo')}
        </Typography>
      </ModalHeader>
      <ModalActions sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => { handleDelete(modal.data.id); }}
          size="small"
        >
          {t('pages.estudios.mis_estudios.modales.eliminar.boton')}
        </Button>
        <Button
          variant="outlined"
          color="tertiary"
          type="button"
          onClick={handleClose}
          size="small"
        >
          {t('botones.cerrar')}
        </Button>
      </ModalActions>    
    </Modal>
  );
}
