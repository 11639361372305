import { Grid, Button, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import { useState } from 'react';
import { useFormik } from 'formik';
import { validacionCelularSchema } from 'schemas/validacion.schema';
import { sendVerifySMS } from 'services/VerifyService';
import onlyNumbersValidate from 'utils/onlyNumbersValidate';
import useAlerts from 'hooks/useAlerts';
import ValidacionSmsForm from './ValidacionSmsFormComponent';

export default function ValidacionCelularForm() {
  const { t } = useTranslation();
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const { openSnackbar } = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const [validarSms, setValidarSms] = useState({
    status: false,
    tiempoSegundos: 0,
    codigoArea: '',
    numeroCelular: '',
  });

  const initialValues = {
    codigoArea: '',
    numeroCelular: '',
  };

  const validacionForm = useFormik({
    initialValues,
    validationSchema: validacionCelularSchema,
    validateOnChange: shouldValidateOnChange,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (data, options) => {
      const { codigoArea, numeroCelular } = data;
      
      try {
        setIsLoading(true);
        const res = await sendVerifySMS(codigoArea, numeroCelular);
        if (res.data.success) {
          setValidarSms({
            status: true,
            tiempoSegundos: res.data.data.expires_in,
            codigoArea,
            numeroCelular,
          });
        } else {
          openSnackbar({
            message: 'Ocurrió un error al enviar el código, por favor contacte a soporte',
            severity: 'error',
          });
        }
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 409) {
          setValidarSms({
            status: true,
            tiempoSegundos: error.response.data.data?.expires_in,
            codigoArea,
            numeroCelular,
          });
        } else {
          openSnackbar({
            message: error.data.message,
            severity: 'error',
          }); 
        }

        options.resetForm();
        setIsLoading(false);
      }
    },
  });

  const submittion = (e) => {
    e.preventDefault();
    setShouldValidateOnChange(true);
    return validacionForm.handleSubmit();
  };

  const handleClickReintentar = () => {
    validacionForm.resetForm();
    setValidarSms((prevState) => ({ ...prevState, status: false }));
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {validarSms.status ? (
        <ValidacionSmsForm
          handleClickReintentar={handleClickReintentar}
          smsCodeTiempoSegundos={validarSms.tiempoSegundos}
          telefonoArea={validarSms.codigoArea}
          telefonoNumero={validarSms.numeroCelular}
        />
      ) : (
        <form onSubmit={submittion}>
          <Grid spacing={{ xs: 3, md: 4 }} container>
            <Grid xs={12} md={6} item>
              <FormikTextField
                id="codigoArea"
                label={t('pages.validar.codigo_area_input')}
                formik={validacionForm}
                validate={onlyNumbersValidate}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">0</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <FormikTextField
                id="numeroCelular"
                label={t('pages.validar.numero_celular_input')}
                formik={validacionForm}
                validate={onlyNumbersValidate}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">15</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={12} mt={{ xs: 0, md: 5 }} item>
              <Button
                variant="contained"
                disabled={!validacionForm.isValid || isLoading}
                fullWidth
                type="submit"
              >
                {t('botones.continuar')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}
