import DataGridCard, {
  updateColumnsDatagrid,
} from 'components/dataGrid/DataGridCard';
import useDeviceHeight from 'hooks/useDeviceHeight';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import EtiquetaEstado from '../EtiquetaEstado';

function getColumns(theme) {
  return [
    {
      field: 'descripcion',
      headerName: 'Curso',
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'categoria',
      headerName: 'Categoria',
      minWidth: 140,
      width: 250,
    },
    {
      field: 'duracion',
      headerName: 'Duración',
      width: 100,
    },
    {
      field: 'fecha',
      headerName: 'Fecha',
      minWidth: 100,
    },
    {
      field: 'estado',
      headerName: '',
      width: 120,
      renderCell: (params) => {
        const { estado } = params.row;
        return <EtiquetaEstado estado={estado} theme={theme} />;
      },
    },
  ];
}

export default function DatagridEmpleado({ estadisticasEmpleado }) {
  const deviceHeight = useDeviceHeight();
  const apiRef = useGridApiRef();
  const [columns] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    updateColumnsDatagrid(getColumns(theme), apiRef);
  }, [estadisticasEmpleado]);

  return (
    <DataGridCard
      height={deviceHeight - 250}
      rows={estadisticasEmpleado}
      columns={columns}
      apiRef={apiRef}
    />
  );
}
