import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import useUserHasPermission from 'hooks/useUserHasPermission';
import Card from 'components/card/CardComponent';
import InfoItemIconContainer from 'components/infoItemIconContainer/InfoItemIconContainer';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import { IconVocabulary } from '@cooperativaobrera/react-icons/tabler-icons';
import { SECONDARY_COLOR } from 'constants/colors';
import {
  PERMISO_CAPACITACION, PERMISO_EDUCACION, 
} from 'constants/permisos';
import CapacitacionesIcon from 'components/icons/CapacitacionesIcon';
import { Trans, useTranslation } from 'react-i18next';
import {
  ESTUDIOS, CAPACITACION, 
} from '../constants';

const sections = [
  {
    id: 0,
    texto: 'Educación',
    permiso: PERMISO_EDUCACION,
    childrens: [
      {
        id: ESTUDIOS,
        texto: 'Visualizar',
        textoUnico: 'Mis estudios externos a la Coope',
        icon: <IconVocabulary sx={{ color: SECONDARY_COLOR, strokeWidth: 1.5 }} />,
      },
    ],
  },
  {
    id: 1,
    texto: 'Capacitaciones',
    permiso: PERMISO_CAPACITACION,
    childrens: [
      {
        id: CAPACITACION,
        texto: 'Visualizar',
        textoUnico: 'Visualiza tus capacitaciones en la Coope',
        icon: <CapacitacionesIcon stroke={SECONDARY_COLOR} strokeWidth={1.8} />,
      },
    ],
  },
];

const styles = {
  button: {
    iconTextContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    },
    width: '100%',
    justifyContent: 'space-between',
  },
};

export default function SeleccionarEstudioCard({ handleClickSolicitud }) {
  const seccionesPermitidas = sections.filter((section) => useUserHasPermission(section.permiso));
  const { t } = useTranslation();
  return (
    <Card>
      <Grid item xs={12} pb={2}>
        <Card bgcolor="white" padding={0}>
          <Typography>  
            <Trans t={t}>
              {t('pages.estudios.mis_estudios.descripcion')}
            </Trans>
          </Typography>
        </Card>
      </Grid>
      <Grid container>
        {seccionesPermitidas.map((section) => (
          <Grid key={section.id} xs={12} item sx={{ pb: 1 }}>
            <Grid spacing={2} container>
              <Grid xs={12} item>
                <Divider />
              </Grid>
              {section.childrens.length > 1 && ( 
                <Grid xs={12} item>
                  <Typography variant="body2" color="secondary">
                    {section.texto}
                  </Typography>
                </Grid>
              )}
              {section.childrens.map((children) => (
                <Grid key={children.id} xs={12} item>
                  <ButtonBase
                    onClick={() => handleClickSolicitud(children.id)}
                    sx={styles.button}
                    disableRipple
                  >
                    <Box sx={styles.button.iconTextContainer}>
                      <InfoItemIconContainer
                        icon={children.icon}
                        size="small"
                      />
                      <Typography textAlign="left" variant="body2">
                        {section.childrens.length > 1 ? children.texto : children.textoUnico}
                      </Typography>
                    </Box>
                    <ArrowRightIcon />
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}
