import {
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { IconCalendar } from '@cooperativaobrera/react-icons/tabler-icons';

export default function FormikDatePicker({
  id,
  label,
  placeholder,
  formik,
  actions,
  height = 48,
  minDate,
  maxDate,
  size,
  disabled,
  shouldDisableDate,
  onError,
  hideErrorMessage,
  slots,
  slotProps,
  sxLabel,
}) {
  return (
    <FormControl
      fullWidth
      error={
        formik.touched[id]
        && !Array.isArray(formik.errors[id])
        && Boolean(formik.errors[id])
      }
      size={size}
      disabled={disabled}
    >
      <InputLabel id={`${id}-label`} sx={sxLabel}>{label}</InputLabel>
      <DatePicker
        labelId={`${id}-label`}
        id={id}
        name={id}
        value={formik?.values[id]}
        onChange={(value) => formik.setFieldValue(id, value)}
        minDate={minDate}
        maxDate={maxDate}
        slots={{
          openPickerIcon: IconCalendar,
          ...slots,
        }}
        slotProps={{
          textField: {
            placeholder,
            sx: {
              '& .MuiInputBase-root': {
                height,
              },
              button: { marginRight: -1 },
            },
          },
          actionBar: {
            actions,
          },
          ...slotProps,
        }}
        disabled={disabled}
        shouldDisableDate={shouldDisableDate}
        onError={onError}
      />
      {formik.touched[id]
        && !Array.isArray(formik.errors[id])
        && Boolean(formik.errors[id])
        && !hideErrorMessage && (
          <FormHelperText>
            {formik.touched[id]
              && !Array.isArray(formik.errors[id])
              && formik.errors[id]}
          </FormHelperText>
      )}
    </FormControl>
  );
}
