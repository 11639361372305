import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  getCasaPersonalCancelar,
  getCasaPersonalCheckAsociado,
  getCasaPersonalSolicitudes,
} from 'services/Ares';
import useAlerts from 'hooks/useAlerts';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import useEmpleado from 'hooks/useEmpleado';
import {
  TARJETA_EMPLEADO,
  TARJETA_JUBILADO,
  TARJETA_SERVICIO,
} from 'constants/tipoTarjeta';
import TyCModal from 'components/TyCModal';
import SolicitarForm from './components/SolicitarForm';
import DataGridSolicitudes from './components/DataGridSolicitudes';
import formatSolicitudesData from './utils/formatSolicitudesData';
import CancelarSolicitudModal from './components/CancelarSolicitudModal';
import CheckingAssociateModal from './components/CheckingAssociateModal';
import HistorialSolicitudesMobile from './components/HistorialSolicitudesMobile';

export default function CasaPersonalPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openSnackbar } = useAlerts();
  const {
    state: {
      empleado: { tarjeta },
    },
  } = useEmpleado();
  const [data, setData] = useState({
    solicitudes: [],
    salones: [],
    turnos: [],
    motivos: [],
    estados: [],
  });
  const [openModal, setOpenModal] = useState({
    open: false,
    data: {
      id: '',
    },
  });
  const [checkingAssociateStatus, setCheckingAssociateStatus] = useState(true);
  const isMobile = useMobileBreakpoint();
  const [terminos, setTerminos] = useState(null);

  const cancelarSolicitud = (id) => {
    getCasaPersonalCancelar(id).then((res) => {
      if (res.data.success) {
        getCasaPersonalSolicitudes().then((res2) => {
          setData(formatSolicitudesData(res2.data.data));
        });
      } else {
        openSnackbar({
          message: res.data.message,
          severity: 'error',
        });
      }
    });
  };

  const handleCancelarSolicitud = (id, fecha) => {
    const [dia, mes, anio] = fecha.split('/');

    const fechaSolicitud = new Date(anio, mes - 1, dia);
    const today = new Date();
    const daysBefore = (fechaSolicitud - today) / 86400000;

    if (daysBefore < 7) {
      setOpenModal({
        open: true,
        data: {
          id,
        },
      });
    } else {
      cancelarSolicitud(id);
    }
  };

  const handleClose = () => {
    setOpenModal((prevState) => ({ ...prevState, open: false }));
  };

  const handleVolver = () => {
    navigate('/tarjeta-empleado');
  };

  useEffect(() => {
    getCasaPersonalCheckAsociado()
      .then(async (res) => {
        if (res.data.success) {
          getCasaPersonalSolicitudes().then((res2) => {
            setData(formatSolicitudesData(res2.data.data));
          });
          setTerminos(res.data.data.terminos);
        } else {
          navigate('/tarjeta-empleado');
          openSnackbar({
            message: res.data.message,
            severity: 'error',
          });
        }
      })
      .finally(() => {
        setCheckingAssociateStatus(false);
      });
  }, []);

  return checkingAssociateStatus ? (
    <CheckingAssociateModal />
  ) : (
    <>
      <CancelarSolicitudModal
        open={openModal.open}
        data={openModal.data}
        handleClose={handleClose}
        cancelarSolicitud={cancelarSolicitud}
      />
      <TyCModal terminos={terminos} handleVolver={handleVolver} />
      <Grid container padding={{ xs: 2, md: 4 }} spacing={2}>
        <Grid xs={12} item>
          <Typography fontWeight={600}>
            {tarjeta === TARJETA_EMPLEADO
              && t('pages.tarjeta_empleado.titulo_empleado')}
            {tarjeta === TARJETA_SERVICIO
              && t('pages.tarjeta_empleado.titulo_servicio')}
            {tarjeta === TARJETA_JUBILADO
              && t('pages.tarjeta_empleado.titulo_jubilado')}
          </Typography>
        </Grid>

        <Grid xs={12} item>
          <Typography>
            {t('pages.tarjeta_empleado.casa_personal.titulo')}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <SolicitarForm
            salones={data.salones}
            turnos={data.turnos}
            motivos={data.motivos}
            setData={setData}
          />
        </Grid>
        <Grid xs={12} item>
          {!isMobile && (
            <DataGridSolicitudes
              solicitudes={data.solicitudes}
              estados={data.estados}
              handleCancelarSolicitud={handleCancelarSolicitud}
            />
          )}
          {isMobile && (
            <HistorialSolicitudesMobile
              solicitudes={data.solicitudes}
              estados={data.estados}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
