export default function CapacitacionIcon({
  width = 24,
  height = 24,
  stroke = '#3C3C3C',
  ...props
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" stroke={stroke} strokeWidth={1.5} {...props}>
      <path d="M22 9 12 5 2 9l10 4 10-4v6" />
      <path d="M6 10.6V16a6 3 0 0 0 12 0v-5.4" />
    </svg>
  );
}
