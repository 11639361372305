import { IconChevronLeft } from '@cooperativaobrera/react-icons/tabler-icons';
import { Button, Grid, Typography } from '@mui/material';
import useEducacion from 'hooks/useEducacion';
import { useTranslation } from 'react-i18next';

export default function BotonVolver() {
  const { handleEstado } = useEducacion();
  const { t } = useTranslation();

  return ( 
    <Grid container alignItems="center">
      <Grid item>
        <Button sx={{ px: 1 }} onClick={() => handleEstado({ componente: 'Listado', data: {} })}>
          <IconChevronLeft fontSize="small" color="text.primary" />
        </Button>
      </Grid>
  
      <Grid item xs display="flex" justifyContent="center">
        <Typography color="text.primary">{t('pages.estudios.mis_estudios.titulo')}</Typography>
      </Grid>
  
      <Grid item sx={{ width: '48px' }} />
    </Grid>
  );
}
