/* eslint-disable no-new */
/* eslint-disable no-console */
import {
  getMessaging, getToken, onMessage, 
} from 'firebase/messaging';

const crearNotificacion = (payload) => {
  const title = payload.data.title || 'Nueva Notificación'; 
  const body = payload.data.body || 'Tienes una nueva notificación.';
  const icon = payload.data.icon || '/android-chrome-192x192.png';
  
  new Notification(title, { body, icon });
};

export async function initializeMessaging(app, handleMessage) {
  try {
    const messaging = getMessaging(app);

    onMessage(messaging, (payload) => {
      handleMessage(payload.notification);
      crearNotificacion(payload);
    });

    return messaging;
  } catch (error) {
    throw ('Error inicializando Firebase messaging:', error);
  }
}

export async function getPermissionAndToken(messaging, registration) {
  try {      
    const permission = await Notification.requestPermission();
    let currentToken;
    if (permission === 'granted') {
      currentToken = await getToken(messaging, { serviceWorkerRegistration: registration });
    }

    return { permissionStatus: permission, currentToken };
  } catch (error) {
    throw ('Ocurrió un error obteniendo el token:', error);
  }
}

export const requestForToken = (messaging) => {
  return getToken(messaging)
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } 
      return null;
    })
    .catch((err) => {
      console.error(`Ocurrió un error obteniendo el token de firebase - ${err}`);
      return null;
    });
};
