import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalidadesTab from './Tabs';
import DatagridEmpleado from './DataGridEmpleado';

export default function EmpleadoDesktop({
  modalidadSeleccionada, 
  handleTabChange, 
  estadisticasEmpleado, 
  estadisticasSeleccionadas,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs>
        <Typography>{t('pages.estudios.capacitacion.curso_formativo_por_modalidad')}</Typography>
        <ModalidadesTab
          handleChange={handleTabChange}
          value={modalidadSeleccionada}
          estadisticasEmpleado={estadisticasEmpleado}
        />
      </Grid>
      <Grid item xs={12}>
        <DatagridEmpleado estadisticasEmpleado={estadisticasSeleccionadas} />
      </Grid>
    </>
  );
}
