import { format } from 'date-fns';
import {
  OTRO_AREA_ID,
  OTRO_TIPO_ESTUDIO_ID,
  OTRO_TITULO_ID,
  TIPO_CURSO_ID,
  TIPO_TERCIARIO_ID,
  TIPO_UNIVERSITARIO_ID,
} from '../constants';

export default function formatEstudios(educacion, values) {
  const tipo_estudio_descripcion = values.tipo_estudio === OTRO_TIPO_ESTUDIO_ID
    ? values.tipo_estudio_descripcion 
    : undefined;

  const area_estudio_descripcion = values.area_estudio === OTRO_AREA_ID 
    ? values.area_estudio_descripcion 
    : undefined;

  const esTipoTerciarioOUniversitario = values.tipo_estudio === TIPO_UNIVERSITARIO_ID 
  || values.tipo_estudio === TIPO_TERCIARIO_ID;
  const esOtroTitulo = values.titulo === OTRO_TITULO_ID;

  const titulo = esTipoTerciarioOUniversitario 
    ? values.titulo 
    : OTRO_TITULO_ID;

  const titulo_descripcion = !esTipoTerciarioOUniversitario || esOtroTitulo
    ? values.titulo_descripcion 
    : undefined;

  const duracion = values.tipo_estudio === TIPO_CURSO_ID
    ? values.duracion 
    : undefined;

  const fecha_inicio = values.tipo_estudio !== TIPO_CURSO_ID && values.fecha_inicio 
    ? format(values.fecha_inicio, 'yyyy-MM-dd')
    : null;

  const fecha_fin = values.tipo_estudio !== TIPO_CURSO_ID && values.fecha_fin 
    ? format(values.fecha_fin, 'yyyy-MM-dd')
    : null;

  return {
    ...values,
    id: educacion?.id ? educacion.id : undefined,
    fecha_inicio,
    fecha_fin,
    tipo_estudio_descripcion,
    titulo,
    titulo_descripcion,
    area_estudio_descripcion,
    duracion,
  };
}
