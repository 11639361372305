import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MODALIDADES } from '../../constants';

export function TabPanel(props) {
  const {
    children, value, index, ...other 
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box px={3} py={1}>
        {children}
      </Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ModalidadesTab({
  handleChange, 
  value, 
  estadisticasEmpleado,
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs onChange={handleChange} value={value}>
          {MODALIDADES.map((modalidad) => (
            <Tab
              key={modalidad.id} 
              label={`${modalidad.nombre} (${estadisticasEmpleado[modalidad.tag]?.length || 0})`}
              {...a11yProps(modalidad.id)}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
}
