import { Grid, MenuItem } from '@mui/material';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import FormikSelect from 'components/selects/FormikSelectComponent';
import useEducacion from 'hooks/useEducacion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  OTRO_TIPO_ESTUDIO_ID, 
  OTRO_TITULO_ID, 
  TIPO_TERCIARIO_ID, 
  TIPO_UNIVERSITARIO_ID, 
} from '../../constants';

const TituloTextField = ({ t, form }) => (
  <FormikTextField
    id="titulo_descripcion"
    label={t('pages.estudios.formulario.titulo')}
    placeholder={t('pages.estudios.formulario.titulo_placeholder')}
    formik={form}
    size="small"
  />
);
const TituloSelect = ({ t, form, filtros }) => (
  <FormikSelect
    id="titulo"
    label={t('pages.estudios.formulario.titulo')}
    formik={form}
    size="small"
    fullWidth
  >
    {filtros?.titulos?.map((carrera) => (
      <MenuItem key={carrera.value} value={carrera.value}>
        {carrera.label}
      </MenuItem>
    ))}
  </FormikSelect>
);

export default function TipoEstudioTitulo({ form }) {
  const { t } = useTranslation();
  const { state: { filtros } } = useEducacion();
  const [esUniversitarioOTerciario, setEsUniversitarioOTerciario] = useState(false);
  const [esOtroTipo, setEsOtroTipo] = useState(false);
  const [esOtroTitulo, setEsOtroTitulo] = useState(false);

  useEffect(() => {
    const { tipo_estudio, titulo } = form.values;

    const universitarioOTerciario = tipo_estudio === TIPO_UNIVERSITARIO_ID 
    || tipo_estudio === TIPO_TERCIARIO_ID;

    const otroTipoEstudio = tipo_estudio === OTRO_TIPO_ESTUDIO_ID;
    const otroTitulo = tipo_estudio === OTRO_TIPO_ESTUDIO_ID || titulo === OTRO_TITULO_ID;

    setEsUniversitarioOTerciario(universitarioOTerciario);
    setEsOtroTipo(otroTipoEstudio);
    setEsOtroTitulo(otroTitulo);
  }, [form.values.tipo_estudio, form.values.titulo]);

  return (
    <>
      {/* Select de Tipo de Estudio */}
      <Grid item xs={12} md={esOtroTipo ? 1.5 : 3} py={1} sx={{ pr: { md: 2 } }}>
        <FormikSelect
          id="tipo_estudio"
          label={t('pages.estudios.formulario.tipo_estudio')}
          formik={form}
          size="small"
          fullWidth
        >
          {filtros?.tipos?.map((tipo) => (
            <MenuItem key={tipo.value} value={tipo.value}>
              {tipo.label}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>

      {/* Si es otro tipo, debe ingresar el tipo de estudio en un textfield */}
      {esOtroTipo && ( 
        <Grid item xs={12} md={1.5} py={1} sx={{ pr: { md: 2 } }}>
          <FormikTextField
            id="tipo_estudio_descripcion"
            label={t('pages.estudios.formulario.tipo_estudio_descripcion')}
            placeholder={t('pages.estudios.formulario.tipo_estudio_placeholder')}
            formik={form}
            size="small"
          />
        </Grid>
      )}
      
      {!esOtroTipo && !esUniversitarioOTerciario && ( 
        <Grid item xs={12} md={4} py={1} sx={{ pr: { md: 2 } }}>
          <TituloTextField t={t} form={form} />
        </Grid>
      )}

      {/* Si es Universitario o terciario, permite seleccionar de un listado */}
      {esUniversitarioOTerciario && (
        <>
          <Grid item xs={12} md={esOtroTitulo ? 2 : 4} py={1} sx={{ pr: { md: 2 } }}>
            <TituloSelect t={t} form={form} filtros={filtros} />
          </Grid>

          {/* Si es es otro titulo, debe ingresar el titulo en un textfield */}
          {esOtroTitulo && ( 
            <Grid item xs={12} md={2} py={1} sx={{ pr: { md: 2 } }}>
              <TituloTextField t={t} form={form} />
            </Grid>
          )}
        </>
      )}

      {/* Si es otro titulo o es otro tipo, debe ingresar el titulo en un textfield */}
      {esOtroTipo && esOtroTitulo && ( 
        <Grid item xs={12} md={4} py={1} sx={{ pr: { md: 2 } }}>
          <TituloTextField t={t} form={form} />
        </Grid>
      )}
    </>
  );
}
