import {
  alpha, Box, Grid, Typography, 
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PorcentajeModalidad from './PorcentajeModalidad';
import { getColor, porcentajeStringToFloat } from '../../utils';
import SemiCircularProgressBar from '../../../../../components/progress/SemiCircularProgressBar';

export default function ResumenEstadisticaEmpleado({ resumen }) {
  const {
    porcentaje_realizado, 
    cursos_totales, 
    cursos_realizados, 
    modalidad,
  } = resumen;
  const { t } = useTranslation();
  const { aula_virtual, presencial, zoom } = modalidad || {};

  const cursosRealizados = porcentajeStringToFloat(porcentaje_realizado);

  const colorTextoCantidadCapacitados = getColor(cursosRealizados);
  const colorBackgroundCantidadCapacitados = alpha(
    colorTextoCantidadCapacitados,
    0.1,
  );

  return (
    <Grid
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      width={1}
    >
      <Box
        display="grid"
        justifyItems="center"
        bgcolor="white"
        borderRadius="10px"
        width={1}
        py={4}
      >
        <Typography fontWeight={500}>
          {t('pages.estudios.capacitacion.cursos_realizados')}
        </Typography>
        <SemiCircularProgressBar valor={cursosRealizados} />
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Box
          bgcolor="white"
          borderRadius="10px"
          py={1}
          flex={1}
          textAlign="center"
        >
          <Typography>
            {cursos_totales}
            {' '}
            cursos
          </Typography>
        </Box>
        <Box
          bgcolor={colorBackgroundCantidadCapacitados}
          borderRadius="10px"
          py={1}
          flex={1}
          textAlign="center"
        >
          <Typography color={colorTextoCantidadCapacitados}>
            {cursos_realizados}
            {' '}
            completados
          </Typography>
        </Box>
        <Box width={1} p={0}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>
              {t('pages.estudios.capacitacion.curso_modalidad')}
              (
              {resumen.cursos_realizados}
              )
            </Typography>
          </Grid>
          <Box bgcolor="white" borderRadius="10px" py={1} width={1}>
            <Grid container gap={2} px={2}>
              <PorcentajeModalidad
                titulo="Aula virtual"
                value={porcentajeStringToFloat(aula_virtual)}
              />
              <PorcentajeModalidad
                titulo="Presencial"
                value={porcentajeStringToFloat(presencial)}
              />
              <PorcentajeModalidad
                titulo="Zoom"
                value={porcentajeStringToFloat(zoom)}
              />
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
