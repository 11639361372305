import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import useUserHasPermission from 'hooks/useUserHasPermission';
import Card from 'components/card/CardComponent';
import InfoItemIconContainer from 'components/infoItemIconContainer/InfoItemIconContainer';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import {
  IconCalculator,
  IconGrill,
  IconReceipt2,
} from '@cooperativaobrera/react-icons/tabler-icons';
import { SECONDARY_COLOR } from 'constants/colors';
import {
  PERMISO_BMJP, PERMISO_RESERVA_SALON, PERMISO_RESUMEN_CUENTA, 
} from 'constants/permisos';
import {
  BMPJ, CASA_PERSONAL, RESUMEN_CUENTA, 
} from '../constants';

const sections = [
  {
    id: 0,
    texto: 'Resumen de cuenta',
    permiso: PERMISO_RESUMEN_CUENTA,
    childrens: [
      {
        id: RESUMEN_CUENTA,
        texto: 'Visualizar',
        textoUnico: 'Resumen de cuenta',
        icon: <IconReceipt2 sx={{ color: SECONDARY_COLOR, strokeWidth: 1.5 }} />,
      },
    ],
  },
  {
    id: 1,
    texto: 'Quincho / Casa del personal',
    permiso: PERMISO_RESERVA_SALON,
    childrens: [
      {
        id: CASA_PERSONAL,
        texto: 'Solicitar reserva',
        textoUnico: 'Solicitar reserva de quincho / casa del personal',
        icon: <IconGrill sx={{ color: SECONDARY_COLOR, strokeWidth: 1.5 }} />,
      },
    ],
  },
  {
    id: 2,
    texto: 'BMPJ',
    permiso: PERMISO_BMJP,
    childrens: [
      {
        id: BMPJ,
        texto: 'Consulta de aportes',
        textoUnico: 'Consulta de aportes',
        icon: <IconCalculator sx={{ color: SECONDARY_COLOR, strokeWidth: 1.5 }} />,
      },
    ],
  },
];

const styles = {
  button: {
    iconTextContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    },
    width: '100%',
    justifyContent: 'space-between',
  },
};

export default function SeleccionarSolicitudCard({ handleClickSolicitud }) {
  const seccionesPermitidas = sections.filter((section) => useUserHasPermission(section.permiso));
  return (
    <Card>
      <Grid container spacing={2}>
        {seccionesPermitidas.map((section, i) => (
          <Grid key={section.id} xs={12} item>
            <Grid spacing={2} container>
              {i !== 0 && (
                <Grid xs={12} item>
                  <Divider />
                </Grid>
              )}
              {section.childrens.length > 1 && (
                <Grid xs={12} item>
                  <Typography variant="body2" color="secondary">
                    {section.texto}
                  </Typography>
                </Grid>
              )}
              {section.childrens.map((children) => (
                <Grid key={children.id} xs={12} item>
                  <ButtonBase
                    onClick={() => handleClickSolicitud(children.id)}
                    sx={styles.button}
                    disableRipple
                  >
                    <Box sx={styles.button.iconTextContainer}>
                      <InfoItemIconContainer
                        icon={children.icon}
                        size="small"
                      />
                      <Typography textAlign="left" variant="body2">
                        {section.childrens.length > 1 ? children.texto : children.textoUnico}
                      </Typography>
                    </Box>
                    <ArrowRightIcon />
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}
