import { Grid } from '@mui/material';
import DatosPersonalesCard from 'components/inicio/DatosPersonalesCard';
import EmpleadoPuestoCard from 'components/inicio/EmpleadoPuestoCard';
import FloatChatbot from 'components/inicio/FloatChatbot/FloatChatbot';
import UsuarioCard from 'components/inicio/UsuarioCard';
import VerNotificacionModalComponent 
  from 'components/notificaciones/modal/VerNotificacionModalComponent';
import { PERMISO_CHAT_BOT } from 'constants/permisos';
import { USUARIO_JUBILADO } from 'constants/tipoUsuario';
import useEmpleado from 'hooks/useEmpleado';
import useFirebase from 'hooks/useFirebase';
import useNotificaciones from 'hooks/useNotificaciones';
import useUserHasPermission from 'hooks/useUserHasPermission';
import { useEffect, useState } from 'react';
import { getAyuda } from 'services/AyudaService';

const InicioView = () => {
  const [firstSteps, setFirstSteps] = useState();
  const { state: { modal }, handleModal } = useNotificaciones();
  const { 
    state: { notificacionRecibida }, 
    setNotificacionActualizada,
  } = useFirebase();
  const chabotHabilitado = useUserHasPermission(PERMISO_CHAT_BOT);

  const {
    state: {
      empleado: { tipoUsuario },
    },
  } = useEmpleado();
  const { obtenerNotificaciones } = useNotificaciones();

  const handleCloseModal = () => {
    handleModal({
      open: '',
      data: {},
    });
  };

  function getFirstSteps() {
    getAyuda().then((res) => {
      if (res.data.data.length > 0) {
        setFirstSteps(res.data.data);
      }
    });
  }

  useEffect(() => {
    obtenerNotificaciones();
    if (chabotHabilitado) {
      getFirstSteps();
    }
  }, []);

  useEffect(() => {
    if (notificacionRecibida) {
      obtenerNotificaciones();
      setNotificacionActualizada();
    }
  }, [notificacionRecibida]);

  return (
    <>
      {modal.open === 'verNotificacion' && (
      <VerNotificacionModalComponent
        open={modal.open === 'verNotificacion'}
        idComunicado={modal.data}
        handleClose={handleCloseModal}
      />
      )} 
      {chabotHabilitado && firstSteps && (
        <FloatChatbot firstSteps={firstSteps} />
      )} 
      <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
        <Grid xs={12} item>
          <UsuarioCard />
        </Grid>
        {tipoUsuario !== USUARIO_JUBILADO && (
        <Grid xs={12} item>
          <EmpleadoPuestoCard />
        </Grid>
        )}
        <Grid xs={12} item>
          <DatosPersonalesCard />
        </Grid>
      </Grid>  
    </>
  );
};

export default InicioView;
