import * as Yup from 'yup';
import { 
  OTRO_AREA_ID, 
  OTRO_TIPO_ESTUDIO_ID, 
  TIPO_CURSO_ID, 
  TIPO_TERCIARIO_ID, 
  TIPO_UNIVERSITARIO_ID,
} from '../constants';

const educacionSchema = () => {
  const schema = {
    tipo_estudio: Yup.number().required('El campo es requerido'),
    tipo_estudio_descripcion: Yup.string()
      .nullable()
      .test('tipo-descripcion-requerida', 'El campo es requerido', function validarTipoDescripcion() {
        const { tipo_estudio, tipo_estudio_descripcion, titulo_descripcion } = this.parent;
        if (tipo_estudio === OTRO_TIPO_ESTUDIO_ID) {
          return !!tipo_estudio_descripcion && tipo_estudio_descripcion.trim().length > 3
          && !!titulo_descripcion && titulo_descripcion.trim().length > 3;
        }
        return true;
      })
      .test('min-sin-espacios', 'El campo debe contener al menos 5 caracteres', (value) => {
        if (!value) return true; // Si no es requerido, se omite esta validación
        const valueWithoutSpaces = value.replace(/\s/g, '');
        return valueWithoutSpaces.length >= 5;
      }),
    titulo: Yup
      .string()
      .nullable()
      .trim()
      .test(
        'titulo-requerido',
        'El campo es requerido para tipo de estudio universitario o terciario',
        function validarTitulo(value) {
          const { tipo_estudio } = this.parent;
          const esRequerido = tipo_estudio === TIPO_UNIVERSITARIO_ID
          || tipo_estudio === TIPO_TERCIARIO_ID;
          return !esRequerido || value;
        },
      ),
    titulo_descripcion: Yup
      .string()
      .nullable()
      .test('titulo-descripcion-requerido', 'El campo es requerido', function validarTituloDescripcion(value) {
        const { tipo_estudio } = this.parent;
        const esRequerido = tipo_estudio !== TIPO_UNIVERSITARIO_ID 
        && tipo_estudio !== TIPO_TERCIARIO_ID;
        return esRequerido ? !!value && value.trim().length > 5 : true;
      }),
    area_estudio: Yup.number().required('El campo es requerido'),
    area_estudio_descripcion: Yup.string().nullable()
      .test('area-descripcion-requerida', 'El campo es requerido', function validarAreaDescripcion(value) {
        const { area_estudio } = this.parent;
        return area_estudio === OTRO_AREA_ID ? !!value && value.trim().length > 3 : true;
      }),
    establecimiento: Yup
      .string()
      .trim()
      .required('El campo es requerido'),
    estado: Yup.number().required('El campo es requerido'),
    fecha_inicio: Yup.date().nullable('El campo es requerido').test(
      'fecha-inicio-validacion', 
      'El campo es requerido', 
      function validarFechaInicio(value) {
        const { tipo_estudio } = this.parent;
        const esRequerido = tipo_estudio !== TIPO_CURSO_ID;
        return !esRequerido || value;
      },
    ),
    fecha_fin: Yup.date().nullable().test(
      'fecha-fin-validacion', 
      'El campo es requerido', 
      function validarFechaFin(value) {
        const { al_presente, tipo_estudio } = this.parent;
        const esRequerido = tipo_estudio !== TIPO_CURSO_ID && !al_presente;
        return !esRequerido || value;
      },
    ),
    duracion: Yup.string().nullable().test(
      'duracion-validacion', 
      'El campo es requerido', 
      function validarFechaFin(value) {
        const { tipo_estudio } = this.parent;
        const esRequerido = tipo_estudio === TIPO_CURSO_ID;
        return !esRequerido || value;
      },
    ),
    al_presente: Yup.boolean().required('El campo es requerido'),
  };

  return Yup.object(schema);
};

export default educacionSchema;
