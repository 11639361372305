import { IconPencil, IconTrash } from '@cooperativaobrera/react-icons/tabler-icons';
import {
  Box, Grid, IconButton, Stack, Typography, 
} from '@mui/material';

export default function InformacionEducacionCard({ 
  id,
  titulo, 
  establecimiento,
  label, 
  handleDelete,
  handleEdit,
}) {
  return (
    <Grid item xs>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={600} color="accent.main">{titulo}</Typography>
        <Box minWidth="60px">
          <IconButton sx={{ p: 0 }} onClick={() => handleEdit(id)}>
            <IconPencil sx={{ stroke: '#3C3C3C', strokeWidth: 1.5 }} />
          </IconButton>
          <IconButton sx={{ p: 0, ml: 1 }} onClick={() => handleDelete(id)}>
            <IconTrash sx={{ stroke: '#3C3C3C', strokeWidth: 1.5 }} />
          </IconButton>
        </Box>
      </Stack>
      <Grid container flexDirection="column">
        <Grid item xs>
          <Typography fontWeight={510}>{establecimiento}</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{label}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
