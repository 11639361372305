export const ANIO_MINIMO = 1950;
export const ESTADO_EN_CURSO = 2;

export const TIPO_UNIVERSITARIO_ID = 4;
export const TIPO_TERCIARIO_ID = 5;

export const OTRO_TIPO_ESTUDIO_ID = 9;
export const OTRO_TITULO_ID = 128;
export const OTRO_AREA_ID = 29;

export const TIPO_CURSO_ID = 7;
