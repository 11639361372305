import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CAPACITACION, ESTUDIOS } from './constants';
import SeleccionarEstudioCard from './components/SeleccionarEstudioCard';

export default function EstudiosPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickSolicitud = (id) => {
    if (id === ESTUDIOS) {
      navigate('/estudios/educacion');
    } else if (id === CAPACITACION) {
      navigate('/estudios/capacitacion');
    }
  };
  
  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <Typography fontWeight={600}>
          {t('pages.estudios.titulo')}
        </Typography>
      </Grid>
      <Grid xs={12} lg={4} item>
        <SeleccionarEstudioCard handleClickSolicitud={handleClickSolicitud} />
      </Grid>
    </Grid>
  );
}
