import { obtenerEducacionAPI, obtenerFiltrosAPI } from 'pages/estudios/educacion/services/EducacionService';
import CreateDataContext from './CreateDataContext';

const educacionReducer = (state, action) => {
  switch (action.type) {
    case 'setFiltros':
      return {
        ...state,
        filtros: action.payload,
      };
    case 'setEducacion':
      return {
        ...state,
        educacion: {
          estudios: action.payload.estudios,
          orden_estudios: action.payload.orden_estudios,
        },
      };
    case 'setModal':
      return {
        ...state,
        modal: action.payload,
      };
    case 'setEstado':
      return {
        ...state,
        estado: action.payload,
      };
    default:
      return state;
  }
};

const obtenerFiltros = (dispatch) => async () => {
  const res = await obtenerFiltrosAPI();

  if (res.data.success) {
    dispatch({ type: 'setFiltros', payload: res.data.data });
  }

  return res;
};

const obtenerEducacion = (dispatch) => async () => {
  const res = await obtenerEducacionAPI();

  if (res.data.success) {
    dispatch({ type: 'setEducacion', payload: res.data.data });
  }

  return res;
};

const handleModal = (dispatch) => (modal) => {
  dispatch({ type: 'setModal', payload: modal });
};

const handleEstado = (dispatch) => (estado) => {
  dispatch({ type: 'setEstado', payload: estado });
};

export const { Context, Provider } = CreateDataContext(
  educacionReducer,
  {
    obtenerFiltros,
    obtenerEducacion,
    handleModal,
    handleEstado,
  },
  {
    filtros: {},
    educacion: { estudios: [], orden_estudios: [] },
    modal: { open: false, data: '' },
    estado: { componente: 'Listado', data: {} },
  },
);
