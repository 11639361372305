import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import FormikDatePicker from 'components/datepicker/FormikDatepickerComponent';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import { ANIO_MINIMO, ESTADO_EN_CURSO, TIPO_CURSO_ID } from '../../constants';

const fechaMinima = new Date(ANIO_MINIMO, 0, 1);
const hoy = new Date();
export default function FechaDuracion({ form }) {
  const { t } = useTranslation();
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [esTipoCurso, setEsTipoCurso] = useState(false);

  const toggleAlPresente = () => {
    const { al_presente } = form.values;
    const alPresenteNuevo = !al_presente;
    if (alPresenteNuevo) {
      form.setFieldValue('fecha_fin', '');
    }
    form.setFieldValue('al_presente', alPresenteNuevo);
  };

  useEffect(() => {
    setFechaInicio(form.values.fecha_inicio);
  }, [form.values.fecha_inicio]);

  useEffect(() => {
    setFechaFin(form.values.fecha_fin);
    if (form.values.fecha_fin) {
      form.setFieldValue('al_presente', false);
    }
  }, [form.values.fecha_fin]);

  useEffect(() => {
    const tipoCurso = form.values.tipo_estudio === TIPO_CURSO_ID;
    setEsTipoCurso(tipoCurso);
  }, [form.values.tipo_estudio]);

  return (
    <>
      {esTipoCurso && ( 
        <Grid item xs={12} md={4} py={1} sx={{ pr: { md: 2 } }}>
          <FormikTextField
            id="duracion"
            label={t('pages.estudios.formulario.duracion')}
            placeholder={t('pages.estudios.formulario.duracion_placeholder')}
            formik={form}
            size="small"
          />
        </Grid>
      )}

      {!esTipoCurso && ( 
      <>
        <Grid item xs={12} md={2} py={1} sx={{ pr: { md: 2 } }}>
          <FormikDatePicker
            id="fecha_inicio"
            label={t('pages.estudios.formulario.fecha_inicio')}
            formik={form}
            size="smaller"
            height={40}
            fullWidth
            minDate={new Date('1977-01-01')}
            maxDate={fechaFin || hoy}
            sxLabel={{ mb: '8px !important' }}
          />
        </Grid>
        <Grid item xs={12} md={2} py={1} sx={{ pr: { md: 2 } }}>
          <Grid>
            <FormikDatePicker
              id="fecha_fin"
              label={t('pages.estudios.formulario.fecha_fin')}
              formik={form}
              size="smaller"
              height={40}
              fullWidth
              minDate={fechaInicio || fechaMinima}
              maxDate={hoy}
              disabled={form.values.estado === ESTADO_EN_CURSO}
              sxLabel={{ mb: '8px !important' }}
            />
          </Grid>
          <FormControlLabel
            control={(
              <Checkbox
                name="presente"
                disabled={form.values.estado !== ESTADO_EN_CURSO}
                checked={form.values.al_presente}
                onChange={toggleAlPresente}
              />
            )}
            sx={{
              position: { md: 'absolute' },
            }}
            label={t('pages.estudios.formulario.presente')}
          />
        </Grid>
      </>
      )}
     
    </>
  );
}
