export const ACTIVIDAD_PENDIENTE = 0;
export const ACTIVIDAD_REALIZADA = 1;
export const ACTIVIDAD_VENCIDA = 2;

export const AULA_VIRTUAL = 0;
export const PRESENCIAL = 1;
export const ZOOM = 2;
export const PENDIENTE = 3;
export const EXTRA = 4;

export const MODALIDADES = [
  { id: AULA_VIRTUAL, nombre: 'Aula virtual', tag: 'aula_virtual' },
  { id: PRESENCIAL, nombre: 'Presencial', tag: 'presencial' },
  { id: ZOOM, nombre: 'Zoom', tag: 'zoom' },
  { id: PENDIENTE, nombre: 'Pendiente', tag: 'pendiente' },
  { id: EXTRA, nombre: 'Extras', tag: 'extra' },
];
