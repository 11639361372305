import useEducacion from 'hooks/useEducacion';
import useAlerts from 'hooks/useAlerts';
import { Grid } from '@mui/material';
import { crearEstudioAPI } from '../services/EducacionService';
import BotonVolver from '../components/BotonVolver';
import Formulario from '../components/formulario/Formulario';

export default function CrearEstudio() {
  const { obtenerEducacion, handleEstado } = useEducacion();
  const { openSnackbar } = useAlerts();

  const handleSubmitCrearEstudio = (data) => {
    crearEstudioAPI(data)
      .then(() => {
        openSnackbar({
          message: 'Estudio guardado correctamente',
          severity: 'success',
        });
        obtenerEducacion();
        handleEstado({ componente: 'Listado', data: {} });
      })
      .catch((res) => openSnackbar({
        message: res.message,
        severity: 'error',
      }));
  };

  return ( 
    <Grid container> 
      <BotonVolver />
      <Formulario 
        titulo="Nuevo estudio" 
        textoBotonGuardar="Guardar estudio"
        handleSubmit={handleSubmitCrearEstudio}
      />
    </Grid>
  );
}
