import {
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useAlerts from 'hooks/useAlerts';
import obtenerEstadisticas from 'services/CapacitacionService';
import useEmpleado from 'hooks/useEmpleado';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { useTranslation } from 'react-i18next';
import ResumenEstadisticaEmpleado from './Resumen/ResumenEstadisticaEmpleado';
import { AULA_VIRTUAL, MODALIDADES } from '../constants';
import EmpleadoDesktop from './Desktop/EmpleadoDesktop';
import EmpleadoMobile from './Mobile/EmpleadoMobile';

export default function EmpleadoEstadistica() {
  const { state: { empleado } } = useEmpleado();
  const { openSnackbar, startLoading, stopLoading } = useAlerts();
  const { t } = useTranslation();

  const [estadisticasEmpleado, setEstadisticasEmpleado] = useState([]);
  const [estadisticasSeleccionadas, setEstadisticasSeleccionadas] = useState([]);
  const [resumenEmpleado, setResumenEmpleado] = useState(null);
  const [modalidadSeleccionada, setModalidadSeleccionada] = useState(AULA_VIRTUAL);
  const isMobile = useMobileBreakpoint();

  const { legajo } = empleado;

  useEffect(() => {
    const loadingId = startLoading('Obteniendo estadisticas');
    obtenerEstadisticas(legajo)
      .then((res) => {
        const {
          total_empleados,
          total_capacitados,
          porcentaje_realizado,
          cursos_totales,
          cursos_realizados,
          modalidad,
          estadisticas,
        } = res.data.data.data;

        const resumen = {
          total_empleados,
          total_capacitados,
          porcentaje_realizado,
          cursos_totales,
          cursos_realizados,
          modalidad,
        };

        setEstadisticasEmpleado(estadisticas);
        setResumenEmpleado(resumen);
      })
      .catch(() => {
        setEstadisticasEmpleado([]);
        openSnackbar({
          message: 'Ocurrió un error inesperado en el servidor',
          severity: 'error',
        });
      })
      .finally(() => stopLoading(loadingId));
  }, [legajo]);

  useEffect(() => {
    if (modalidadSeleccionada === AULA_VIRTUAL && estadisticasEmpleado?.aula_virtual) {
      setEstadisticasSeleccionadas(estadisticasEmpleado.aula_virtual);
    }
  }, [modalidadSeleccionada, estadisticasEmpleado]);

  const handleTabChange = (event, newValue) => {
    const modalidad = MODALIDADES.find((m) => m.id === newValue);
    setEstadisticasSeleccionadas(estadisticasEmpleado[modalidad.tag] || []);
    setModalidadSeleccionada(newValue);
  };

  const handleChangeModalidad = (event) => {
    const modalidad = MODALIDADES.find((m) => m.id === event.target.value);
    setEstadisticasSeleccionadas(estadisticasEmpleado[modalidad.tag] || []);
    setModalidadSeleccionada(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <Typography fontWeight={600}>{t('pages.estudios.titulo')}</Typography>
      </Grid>

      <Grid xs={12} item>
        <Typography>{t('pages.estudios.capacitacion.titulo')}</Typography>
      </Grid>

      <Grid container item gap={2}>
        <Grid item flexDirection="column" xs>
          {resumenEmpleado != null && ( 
            <ResumenEstadisticaEmpleado resumen={resumenEmpleado} />
          )}
        </Grid>

        {isMobile ? (
          <Grid item xs={12} lg={8.5}>
            <EmpleadoMobile 
              modalidadSeleccionada={modalidadSeleccionada}
              handleChangeModalidad={handleChangeModalidad}
              estadisticasSeleccionadas={estadisticasSeleccionadas}
            />
          </Grid>
        ) : ( 
          <Grid container item xs={12} lg={8.5} p={3} border={1} borderRadius="10px" borderColor="line.main">
            <EmpleadoDesktop 
              modalidadSeleccionada={modalidadSeleccionada}
              handleTabChange={handleTabChange}
              estadisticasEmpleado={estadisticasEmpleado}
              estadisticasSeleccionadas={estadisticasSeleccionadas}
              resumenEmpleado={resumenEmpleado}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
