import { Grid } from '@mui/material';
import { useEffect } from 'react';
import useEducacion from 'hooks/useEducacion';
import EliminarEstudioModal from './misestudios/EliminarEstudioModal';
import CrearEstudio from './misestudios/CrearEstudio';
import Listado from './components/Listado';
import EditarEstudio from './misestudios/EditarEstudio';

export default function EducacionView() {
  const {
    state: { 
      educacion, 
      modal, 
      estado, 
    }, 
    obtenerEducacion, 
    handleModal, 
    handleEstado,
  } = useEducacion();

  useEffect(() => {
    obtenerEducacion();
    handleEstado({ componente: 'Listado', data: {} });
  }, []);

  const handleCloseModal = () => {
    handleModal({ open: '', data: '' });
  };

  return (
    <Grid container item padding={{ xs: 2, md: 4 }} spacing={2}>
      {modal.open === 'EliminarEstudio' && ( 
      <EliminarEstudioModal
        modal={modal}
        handleClose={handleCloseModal}
      />
      )}

      {estado.componente === 'Listado' && educacion && (
        <Grid xs={12} item>
          <Listado />
        </Grid>
      )}

      <Grid xs={12} item>
        {estado.componente === 'CrearEstudio' && <CrearEstudio />}
        {estado.componente === 'EditarEstudio' && <EditarEstudio />}
      </Grid>
    </Grid>
  );
}
