/* eslint-disable no-console */
// Importa las funciones necesarias de los SDK que necesitas
import { initializeApp, getApps } from 'firebase/app';
import {
  getAnalytics,
  logEvent as firebaseLogEvent,
  isSupported as analyticsIsSupported,
} from 'firebase/analytics';
import {
  API_KEY,
  APP_ID,
  AUTH_DOMAIN,
  MEASUREMENTE_ID,
  MESSAGING_SENDER_ID,
  PROYECT_ID,
  STORAGE_BUCKET,
} from 'constants/firebase';
import { isSupported as messagingIsSupported } from 'firebase/messaging';
import { initializeMessaging, requestForToken } from './firebaseMessaging';
import inicializarFirebaseMessagingSW from './serviceWorker';

// Configuración de Firebase para tu aplicación web
// Para Firebase JS SDK v7.20.0 en adelante, measurementId es opcional
const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROYECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENTE_ID,
};

let app;
let analytics;
let messaging;
let logEvent;
let registration;

let firebaseInstance = null;

async function initializeFirebase(handleMessage, inicializarServiceWorker) {
  if (firebaseInstance && messaging) {
    return firebaseInstance;
  }

  if (!getApps().length) {
    app = initializeApp(firebaseConfig);
  } else {
    // Si la app ya está inicializada, obtén la instancia existente
    [app] = getApps();
  }

  // Verificar compatibilidad de analytics antes de inicializar la aplicación
  if (await analyticsIsSupported()) {
    // Inicializa Analytics
    try {
      analytics = getAnalytics(app);
      // Función modificada para recibir todos los parámetros excepto analytics
      logEvent = (eventName, eventParams) => {
        // Completar la instancia de analytics
        firebaseLogEvent(analytics, eventName, eventParams);
      };
    } catch (e) {
      console.error(e);
    }
  } else {
    console.warn('Firebase Analytics no es compatible en este navegador.');
  }

  // Verificar compatibilidad de messaging antes de inicializar la aplicación
  if (inicializarServiceWorker) {
    if (await messagingIsSupported()) {
      try {
        // Inicializa Firebase Messaging     
        registration = await inicializarFirebaseMessagingSW();
        messaging = await initializeMessaging(app, handleMessage);
      } catch (e) {
        console.error(e);
      }
    } else {
      console.warn('Service worker no es compatible en este navegador.');
    }
  }
  
  firebaseInstance = {
    app, analytics, messaging, logEvent, requestForToken, registration,
  };

  return firebaseInstance;
}

export default initializeFirebase;
