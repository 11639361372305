import { alpha, Box, Typography } from '@mui/material';
import { getColorByEstado, getEstadoLabel } from '../utils';

export default function EtiquetaEstado({ estado, theme }) {
  const estadoLabel = getEstadoLabel(estado);
  const color = getColorByEstado(estado, theme);
  const bgColor = alpha(color, 0.1);
  return (
    <Box
      bgcolor={bgColor}
      borderRadius="10px"
      px={1}
      py={0.5}
      flex={1}
      textAlign="center"
    >
      <Typography color={color}>{estadoLabel}</Typography>
    </Box>
  );
}
