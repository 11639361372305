import useEducacion from 'hooks/useEducacion';
import useAlerts from 'hooks/useAlerts';
import { Grid } from '@mui/material';
import { editarEstudioAPI } from '../services/EducacionService';
import BotonVolver from '../components/BotonVolver';
import Formulario from '../components/formulario/Formulario';

export default function EditarEstudio() {
  const { state: { estado }, obtenerEducacion, handleEstado } = useEducacion();
  const { openSnackbar } = useAlerts();

  const handleSubmitEditarEstudio = (data) => {
    editarEstudioAPI(data.id, data)
      .then(() => {
        openSnackbar({
          message: 'Estudio guardado correctamente',
          severity: 'success',
        });
        obtenerEducacion();
        handleEstado({ componente: 'Listado', data: {} });
      })
      .catch((res) => openSnackbar({
        message: res.message,
        severity: 'error',
      }));
  };

  return ( 
    <Grid container> 
      <BotonVolver />
      <Formulario 
        titulo="Editar estudio" 
        textoBotonGuardar="Editar estudio"
        educacion={estado.data}
        handleSubmit={handleSubmitEditarEstudio}
      />
    </Grid>
  );
}
