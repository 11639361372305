import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useEducacion from 'hooks/useEducacion';
import ListadoEstudios from '../misestudios/listado/ListadoEstudios';

export default function Listado() {
  const { t } = useTranslation();
  const { state: { educacion }, handleModal } = useEducacion();
  return ( 
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <Typography fontWeight={600}>
          {t('pages.estudios.titulo')}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography>{t('pages.estudios.mis_estudios.titulo')}</Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <ListadoEstudios estudios={educacion.estudios} setModal={handleModal} />
      </Grid>
    </Grid>
  );
}
