import empleadosApi from 'apis/EmpleadosApi';

const URL_EDUCACION = '/educacion';
const URL_EDUCACION_ESTUDIOS = '/educacion/estudios';

export function obtenerFiltrosAPI() {
  return empleadosApi.get(`${URL_EDUCACION}/filtros`);
}

export function obtenerEducacionAPI() {
  return empleadosApi.get(URL_EDUCACION);
}

/* Estudios */
export function crearEstudioAPI(data) {
  return empleadosApi.post(URL_EDUCACION_ESTUDIOS, { ...data });
}

export function editarEstudioAPI(id, data) {
  return empleadosApi.put(`${URL_EDUCACION_ESTUDIOS}/${id}`, { ...data });
}

export function eliminarEstudioAPI(id) {
  return empleadosApi.delete(`${URL_EDUCACION_ESTUDIOS}/${id}`);
}
