import { Divider, Grid, Typography } from '@mui/material';
import Card from 'components/card/CardComponent';
import useEducacion from 'hooks/useEducacion';
import dateStringToFormatedDate from 'utils/dateStringToFormatedDate';
import { useEffect, useState } from 'react';
import InformacionEducacionCard from '../../components/InformacionEducacionCard';
import BotonAgregar from './BotonAgregar';
import { OTRO_TIPO_ESTUDIO_ID } from '../../constants';

export default function ListadoEstudios({ estudios, setModal }) {
  const { state: { educacion }, handleEstado } = useEducacion();
  const [estudiosAgrupados, setEstudiosAgrupados] = useState([]);

  const handleDelete = (id) => {
    setModal({ open: 'EliminarEstudio', data: { id } });
  };

  const handleEdit = (id) => {
    const estudioSeleccionado = educacion.estudios.find((estudio) => estudio.id === id);
    handleEstado({ componente: 'EditarEstudio', data: { ...estudioSeleccionado } });
  };

  const formatearFecha = (fecha) => {
    return dateStringToFormatedDate(`${fecha}T00:00`).replaceAll('/', '-');
  };

  const obtenerEstadoLabel = (fecha_inicio, fecha_fin, estado_label) => {
    const fechaInicio = fecha_inicio ? formatearFecha(fecha_inicio) : '';
    const fechaFin = fecha_fin ? `- ${formatearFecha(fecha_fin)}` : '';
    const estadoLabel = fecha_inicio 
      ? `${estado_label}. ${fechaInicio} ${fechaFin}` 
      : estado_label;
    return estadoLabel;
  };

  useEffect(() => {
    if (educacion.orden_estudios) {
      let agrupacion = estudios.reduce((resultado, estudio) => {
        const tipoEstudio = estudio.tipo_estudio;
        const ordenEstudios = educacion.orden_estudios;
        const { id, orden, label } = ordenEstudios.find(
          (ordenEstudio) => ordenEstudio.id === tipoEstudio,
        );

        if (!resultado[orden]) {
          resultado[orden] = { id, label, estudios: [] };
        }
    
        resultado[orden].estudios.push(estudio);
        return resultado;
      }, []);
      agrupacion = agrupacion.filter((item) => item != null);

      // Mover "Otros" al final
      const otrosIndex = agrupacion.findIndex((item) => item.id === OTRO_TIPO_ESTUDIO_ID);
      if (otrosIndex !== -1) {
        const otros = agrupacion.splice(otrosIndex, 1);
        agrupacion.push(otros[0]);
      }
      setEstudiosAgrupados(agrupacion);
    }
  }, [educacion]);

  return ( 
    <Card sx={{ py: '8px !important' }}>
      <BotonAgregar cantidadEstudios={estudios.length ?? 0} />

      {estudios.length > 0 && ( 
        <Divider />
      )}

      {estudiosAgrupados?.length > 0 && estudiosAgrupados.map((agrupacion, index) => (
        agrupacion.estudios?.length > 0 && (
          <Grid container flexDirection="column" mt={2} key={`tipo-${agrupacion.label}`}>
            <Typography>{agrupacion.label}</Typography>
            
            <Grid container flexDirection="column" my={2} rowGap={2}>
              {agrupacion.estudios.map((estudio) => {
                const label = obtenerEstadoLabel(
                  estudio.fecha_inicio, 
                  estudio.fecha_fin, 
                  estudio.estado_label,
                );
                return (
                  <InformacionEducacionCard 
                    id={estudio.id}
                    key={estudio.id}
                    titulo={estudio.titulo_label} 
                    establecimiento={estudio.establecimiento} 
                    label={label}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                );
              })}
            </Grid>
            {index !== estudiosAgrupados.length - 1 && <Divider />}
          </Grid>
        )
      ))}
    </Card>
  );
}
