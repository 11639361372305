/* eslint-disable no-empty */
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import useEmpleado from 'hooks/useEmpleado';
import { useEffect } from 'react';
import HistorialSolicitudesCard from './components/HistorialSolicitudesCardComponent';
import HistorialSolicitudesMobile from './components/HistorialSolicitudesMobileComponent';
import SolicitarCertificadoLaboralCard from './components/SolicitarCertificadoLaboralCardComponent';

export default function CertificacionLaboralPage() {
  const { t } = useTranslation();
  const isMobile = useMobileBreakpoint();
  const {
    obtenerSolicitudes,
    state: {
      empleado: { solicitudes },
    },
  } = useEmpleado();

  useEffect(() => {
    if (solicitudes.length === 0) {
      obtenerSolicitudes();
    }
  }, []);

  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={2}>
      <Grid xs={12} item>
        <Typography fontWeight={600}>
          {t('pages.solicitudes.titulo')}
        </Typography>
      </Grid>

      <Grid xs={12} item>
        <Typography>{t('pages.solicitudes.certificado_laboral')}</Typography>
      </Grid>

      <Grid xs={12} item>
        <Grid spacing={{ xs: 2, md: 4 }} container>
          <Grid xs={12} lg={4} item>
            <SolicitarCertificadoLaboralCard />
          </Grid>
          {!isMobile && (
            <Grid md={12} lg={8} item>
              <HistorialSolicitudesCard historial={solicitudes} />
            </Grid>
          )}
          {isMobile && (
            <Grid xs={12} item>
              <HistorialSolicitudesMobile historial={solicitudes} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
